import { isSuccess, isFailure } from "common/util";
import { combineReducers } from "redux";

export const actionTypes = {
  GET_ME_DETAILS: "[Me] Get me details",
  UPDATE_ONBOARDING_STATUS: "[Me] Update onboarding status",
  SEND_EMAIL: "[Users] send email",
  GET_USER_PLAN: "[Users] get plan details",
  GET_USER_COINS: "[Users] get user coin count",
};

export const actions = {
  getMe: (payload) => ({
    type: actionTypes.GET_ME_DETAILS,
    payload,
  }),  
  getMeSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_ME_DETAILS),
    data
  }),
  getMeFailure: (error) => ({
    type: isFailure(actionTypes.GET_ME_DETAILS),
    error,
  }),
  getCoinCount: (payload) => ({
    type: actionTypes.GET_USER_COINS,
    payload,
  }),  
  getCoinCountSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_USER_COINS),
    data
  }),
  getCoinCountFailure: (error) => ({
    type: isFailure(actionTypes.GET_USER_COINS),
    error,
  }),
  updateOnboarding: () => ({
    type: actionTypes.UPDATE_ONBOARDING_STATUS,
  }),  
  updateOnboardingSuccess: (data) => ({
    type: isSuccess(actionTypes.UPDATE_ONBOARDING_STATUS),
    data
  }),
  updateOnboardingFailure: (error) => ({
    type: isFailure(actionTypes.UPDATE_ONBOARDING_STATUS),
    error,
  }),
  sendEmail: (payload) => ({
    type: actionTypes.SEND_EMAIL,
    payload,
  }),  
  sendEmailsSuccess: (data) => ({
    type: isSuccess(actionTypes.SEND_EMAIL),
    data
  }),
  sendEmailsFailure: (error) => ({
    type: isFailure(actionTypes.SEND_EMAIL),
    error,
  }),
  getUserPlan: (payload) => ({
    type: actionTypes.GET_USER_PLAN,
    payload,
  }),  
  getUserPlanSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_USER_PLAN),
    data
  }),
  getUserPlanFailure: (error) => ({
    type: isFailure(actionTypes.GET_USER_PLAN),
    error,
  }),
};

const getMeInitialState = {
  data: [],
  error: '',
  fetched: false,
  loading: false
};

const getCoinCountInitialState = {
  data: [],
  error: '',
  loading: false
};

const onboardedInitialState = {
  data: [],
  error: '',
  loading: false
};

const sendEmailInitialState = {
  data: [],
  error: '',
  loading: false
};

const getUserPlanInitialState = {
  data: [],
  error: '',
  fetched: false,
  loading: false
};

export const initialState = {
  getMe: getMeInitialState,
  onboarded: onboardedInitialState,
  getUserInfo: sendEmailInitialState,
  getUserPlan: getUserPlanInitialState,
}

function getMeReducer (state = getMeInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_ME_DETAILS:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null
      };
    case isSuccess(actionTypes.GET_ME_DETAILS):
      return {
        ...state,
        data: action.data,
        fetched: true,
        loading: false,
        error: null
      }
    case isFailure(actionTypes.GET_ME_DETAILS):
      return {
        ...state,
        fetched: true,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function getCoinCountReducer (state = getCoinCountInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_COINS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case isSuccess(actionTypes.GET_USER_COINS):
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      }
    case isFailure(actionTypes.GET_USER_COINS):
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function updateOnboardingReducer (state = onboardedInitialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ONBOARDING_STATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case isSuccess(actionTypes.UPDATE_ONBOARDING_STATUS):
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      }
    case isFailure(actionTypes.UPDATE_ONBOARDING_STATUS):
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

function sendEmailReducer (state = sendEmailInitialState, action) {
  switch (action.type) {
    case actionTypes.SEND_EMAIL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case isSuccess(actionTypes.SEND_EMAIL):
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      }
    case isFailure(actionTypes.SEND_EMAIL):
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function getUserPlanReducer (state = getUserPlanInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_PLAN:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null
      };
    case isSuccess(actionTypes.GET_USER_PLAN):
      return {
        ...state,
        data: action.data,
        fetched: true,
        loading: false,
        error: null
      }
    case isFailure(actionTypes.GET_USER_PLAN):
      return {
        ...state,
        fetched: true,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const meRootReducer = combineReducers({
  meReducer: getMeReducer,
  coinCountReducer: getCoinCountReducer,
  updateOnboarding: updateOnboardingReducer,
  sendEmails: sendEmailReducer,
  getUserPlan: getUserPlanReducer,
});

export default meRootReducer