import { takeLatest, put, select } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { actions as userActions } from "../users/ducks"
import api from "../api";
import { selectAccessToken } from "authSelector"; 

export default [
  takeLatest(actionTypes.SEARCH_LEADS_DATA, onSearchLeads),
];

function* onSearchLeads(action) {
  try {
    const accessToken = yield select(selectAccessToken);
    const { objective, searchStrategy, chainStrategy } = action.payload;
    const response = yield api.post(
      'https://sonic.pulseco.ai/searches/',
      {
        Authorization: `Bearer ${accessToken}`,
      },
      {
        objective: objective,
        search_strategy: searchStrategy || 'PDL_STRATEGY',
        chain_strategy: chainStrategy || 'OPEN_AI_STRATEGY',
      },
      {},
    );
    yield put(actions.searchLeadsSuccess(response.data));
    yield put(userActions.getCoinCount())
  } catch (error) {
    const { response } = error;
    yield put(actions.searchLeadsFailure(response.data));
  }
}

