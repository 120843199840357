import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../users/ducks';
import { EmailOutlined, Google, Person, Close, TollOutlined } from '@mui/icons-material';
import useProfilePicture from 'common/useProfilePicture';
import Spinner from 'common/spinner';

// TODO: Correct the redirect from google login
// Allow user to signout and add a different mailbox

const Profile = () => {
  const redirectUri = process.env.REACT_APP_SEND_URI_PROD;
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const profilePic = useProfilePicture();
  const userDetails = useSelector((state) => state.me.meReducer.data);
  const userPlan = useSelector((state) => state.me.getUserPlan);
  const coinCount = useSelector((state) => state.me.coinCountReducer)
  const meLoading = useSelector((state) => state.me.meReducer.loading);

  const [showPopup, setShowPopup] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false)
  const [showPurchasePopup, setShowPurchasePopup] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);

  useEffect(() => {
    dispatch(actions.getCoinCount());
    dispatch(actions.getUserPlan());
  }, [dispatch]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp * 1000);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
      }/${year}`;
  };

  const handleConfirmCancel = () => {
    // Handle subscription cancellation logic here
    setShowPopup(false);
  };

  const handleUpgradeSubscription = () => {
    console.log("Upgrading subscription...");
    setShowPopup(false);
  };

  const handlePurchaseCoins = () => {
    setShowPurchasePopup(true);
  };

  const handleConfirmPurchase = () => {
    setIsPurchasing(true);
    // Add the logic for processing the coin purchase here
    setTimeout(() => {
      setIsPurchasing(false);
      setShowPurchasePopup(false);
    }, 2000);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="w-full max-w-4xl p-6 bg-white rounded-xl space-y-6">
        <div className="flex space-x-6">
          <div className="w-1/2 p-4 border rounded-xl shadow-lg">
            <div className="text-2xl font-bold mb-4">Profile</div>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                {meLoading ? (
                  <Spinner />
                ) : profilePic ? (
                  <img
                    src={profilePic}
                    referrerPolicy="no-referrer"
                    alt="user profile picture"
                    className="rounded-full w-12 h-12 mr-3"
                  />
                ) : (
                  <Person className="rounded-full w-20 h-20 mt-2 mr-2" />
                )}
                <div className="text-lg font-medium">{userDetails ? userDetails.name : ""}</div>
              </div>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow mt-2 hover:bg-blue-600 transition"
                onClick={handlePurchaseCoins}
              >
                Purchase Coins
              </button>
            </div>
            <div className="flex flex-col items-center mb-4">
              <div className="flex items-center justify-center mt-2">
                <p>Current Coin Balance:</p>
                <TollOutlined className="text-yellow-500 mr-1 ml-2" />
                {coinCount.loading ? (
                  <Spinner />
                ) : (
                  <p>
                    {coinCount.data === undefined || coinCount.data === null || coinCount.data === 0
                      ? "Add Coins"
                      : coinCount.data}
                  </p>
                )}
              </div>
            </div>
            <button
              className="p-2 rounded-lg border mt-4 w-full text-gray-800 hover:bg-gray-100 transition"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Logout
            </button>
          </div>

          <div className="w-1/2 p-4 border rounded-xl shadow-lg">
            <div className="text-2xl font-bold mb-2">Billing</div>
            {userPlan?.loading ? (
              <Spinner />
            ) : (
              <>
                <div className="mt-4">
                  <div className="font-bold">
                    Current Plan: {userPlan?.data?.product_name || "N/A"}
                  </div>
                  <div className="mt-1">
                    Description: {userPlan?.data?.product_description || "N/A"}
                  </div>
                  <div className="mt-1">
                    Monthly Billing: $
                    {userPlan?.data?.subscription_cost != null
                      ? userPlan.data.subscription_cost.toFixed(2)
                      : "N/A"}
                  </div>
                  <div className="mt-1">
                    Next Payment Due:{" "}
                    {userPlan?.data?.next_billing_date
                      ? formatDate(userPlan.data.next_billing_date)
                      : "N/A"}
                  </div>
                </div>
                <button
                  onClick={() => setShowPopup(true)}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
                >
                  Manage
                </button>
              </>
            )}
          </div>
        </div>
        {showPurchasePopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-gray-800">Purchase Coins</h3>
                <button
                  onClick={() => setShowPurchasePopup(false)}
                  className="text-gray-500 hover:text-gray-800 transition"
                >
                  <Close />
                </button>
              </div>
              {isPurchasing ? (
                <>
                  <p className="text-gray-700 mb-4">Processing your purchase...</p>
                </>
              ) : (
                <>
                  <p className="text-gray-700 mb-4">Coin top off coming soon!</p>
                  {/* Place stripe one off coin purchases here */}
                </>
              )}
            </div>
          </div>
        )}

        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold text-gray-800">
                  Manage Subscription
                </h3>
                <button
                  onClick={() => setShowPopup(false)}
                  className="text-gray-500 hover:text-gray-800 transition"
                >
                  <Close />
                </button>
              </div>
              {isCanceling ? (
                <>
                  <p className="text-gray-700 mb-4">
                    Are you sure you want to cancel your subscription?
                  </p>
                  <div className="flex justify-center mt-2 mb-2 space-x-4">
                    <button
                      onClick={handleConfirmCancel}
                      className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition"
                    >
                      Confirm Cancel
                    </button>
                    <button
                      onClick={() => setIsCanceling(false)}
                      className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md shadow hover:bg-gray-400 transition"
                    >
                      Back
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-gray-700 mb-4">
                    Here you can update your subscription. Select an action below:
                  </p>
                  <div className="flex justify-center mt-2 mb-2 space-x-4">
                    <button
                      onClick={() => setIsCanceling(true)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition"
                    >
                      Cancel Subscription
                    </button>
                    {/* 
                    <button
                      onClick={handleUpgradeSubscription}
                      className="bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600 transition"
                    >
                      Upgrade Subscription
                    </button>
                    */}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className="flex space-x-6">
          {/* <div className="w-1/2 p-4 border rounded-lg shadow-lg">
            <div className="text-2xl font-bold">Connect Lists</div>
            <div className="flex justify-center items-center h-32 border border-dashed border-gray-300 rounded-lg">
              <p>Coming soon!!</p>
            </div>
          </div> */}
          <div className="w-1/2 p-4 border rounded-lg shadow-lg">
            <div className="text-2xl font-bold">Send outreach from</div>
            <div className="space-y-2">
              {userDetails && userDetails.user_info ? (
                userDetails.user_info.meta?.credentials?.refresh_token ? (
                  <div className="flex mt-4">
                    <EmailOutlined className="mr-2" />
                    <p>{userDetails.user_info.user_id}</p>
                  </div>
                ) : (
                  <div>
                    <p className="font-semibold text-sm">
                      Note: Please select the same email you registered with
                    </p>
                    <a
                      href={`https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=306269071464-nl9fsl63a4el8qh1sdh9m3f3lrhk7d08.apps.googleusercontent.com&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&state=tbb3cU7uAdS0BXVmnWQKCZ1L4Xr0fl&access_type=offline`}
                      className="flex items-center mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      <Google className="mr-2" />
                      Connect an email to send pulses
                    </a>
                  </div>
                )
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
