import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
// import Spinner from 'common/spinner';
import { actions } from "./ducks";
import { useSelector, useDispatch } from 'react-redux';
import { truncateText } from 'common/trimObjective';
import Loader from "../loader/index.js";
import ErrorModal from 'common/errorModal.js';
import NavIconAndPage from 'common/leads/navIconAndPage';
import MarketPlace from 'marketplace';
import { ArrowUpward, ArrowForwardIos, TollOutlined } from '@mui/icons-material';
import { communityActions } from 'communities/ducks';
import useCommunitySave from './hooks';
// import useProfilePicture from 'common/useProfilePicture';
import { formatNumber } from 'common/formatNumber';
// import mockSearch from "../mockSearch.json"

// TODO: Refactor things like truncate text to commons, decouple naviconandpage
// TODO: Make tooltip work

const SearchCommunities = () => {
  const [searchType, setSearchType] = useState('lead');
  const [objective, setObjective] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const searchSelector = useSelector((state) => state.search);
  const communitySelector = useSelector(state => state.community);
  const pulseSelector = useSelector((state) => state.pulses);
  const meLoading = useSelector((state) => state.me.meReducer.loading);
  const coinCount = useSelector((state) => state.me.coinCountReducer);
  const coinCountData = coinCount?.data;
  const dispatch = useDispatch();
  const { handleSave } = useCommunitySave();
  const communityIsSaved = communitySelector.saveCommunity?.communityIsSaved

  const isCoinsAvailable = coinCountData >= 100;
  const isCoinsInsufficient = coinCountData < 100;
  const isCoinsUnavailable = coinCountData == null || undefined;

  useEffect(() => {
    if (searchSelector.search && searchSelector.search.error) {
      setModalMessage(searchSelector.search.error.detail || 'An error occurred during your search');
      setModalOpen(true);
    }
  }, [searchSelector.search && searchSelector.search.error]);

  useEffect(() => {
    if (!pulseSelector.savePulse.loading && pulseSelector.savePulse.error) {
      setModalMessage(pulseSelector.error.detail[0].msg || 'An error occured when setting pulses');
      setModalOpen(true);
    }
  }, [pulseSelector && pulseSelector.savePulse.error])

  // TODO this will be once we implement rolodex search
  // const handleToggle = (type) => {
  //   setSearchType(type);
  // };

  const tooltipText = isCoinsUnavailable || isCoinsInsufficient
    ? "Please add coins or wait for your coins to refresh. Searches cost 100 coins."
    : "Search for your ICP by single title, industry, and location";


  const handleNewSearchSubmit = (e) => {
    e.preventDefault();
    if (objective.trim() !== '') {
      dispatch(
        actions.searchLeads({
          objective: objective,
          searchStrategy: 'PDL_STRATEGY',
          chainStrategy: 'OPEN_AI_STRATEGY',
        })
      );
    }
  };

  const handlePersonSearchSubmit = (e) => {
    // TODO: add the rolodex search endpoint here
  };


  const data = searchSelector.search?.data;
  const leads = data?.leads || [];
  const total = data?.total || 0;

  let percentage;
  if (total === 0) {
    percentage = "0";
  } else if (leads.length === total) {
    percentage = "100";
  } else {
    percentage = ((leads.length / total) * 100).toFixed(1);
  }

  const circleCircumference = 2 * Math.PI * 72;
  const strokeDashoffset = total === 0 ? circleCircumference : circleCircumference * (1 - leads.length / total);


  return (
    <>
      {modalOpen && (
        <ErrorModal show={true} message={modalMessage} onClose={() => setModalOpen(false)} />
      )}
      {searchSelector?.search?.loading ? (
        <div className="flex items-center justify-center h-screen">
          <span className="mr-4 text-lg">Deep scanning and verifying results...</span>
          <div className="relative">
            <Loader />
          </div>
        </div>
      ) : searchSelector?.search?.data ? (
        <div>
          {communitySelector.saveCommunity.loading ? (
            <div className="flex items-center justify-center h-screen">
              <span className="mr-4 text-lg">Preparing your community...</span>
              <div className="relative">
                <Loader />
              </div>
            </div>
          ) : communityIsSaved ? (
            <MarketPlace
              fromPath={"search"}
            />
          ) : (
            <>
              <div className="relative flex items-center mt-4 ml-4 rounded-lg bg-white z-10">
                <div className="relative flex items-center p-4 bg-gray-200 text-md rounded-full mr-4 z-10 w-80">
                  <img src={`${process.env.PUBLIC_URL}/pulse-logo-icon.png`} alt="Logo" className="rounded-full w-6 h-6 mr-4" />
                  <p className="mr-2">{truncateText(searchSelector.search.data?.objective, 55)}</p>
                  <button
                    className="border-l border-gray-500 flex items-center"
                    onClick={() => {
                      dispatch(actions.resetLeadsData());
                      if (communityIsSaved) {
                        dispatch(communityActions.resetCommunityIsSaved());
                      }
                    }}
                  >
                    <h2 className='text-gray-500 font-semibold ml-2'>Clear</h2>
                  </button>
                </div>
                <div className="flex items-center justify-center ml-20">
                  <svg width="60" height="60" viewBox="0 0 60 60" className="flex-shrink-0">
                    <circle
                      cx="30"
                      cy="30"
                      r="21.6"
                      stroke="#e6e6e6"
                      strokeWidth="4"
                      fill="none"
                    />
                    <circle
                      cx="30"
                      cy="30"
                      r="21.6"
                      stroke="#007bff"
                      strokeWidth="4"
                      fill="none"
                      strokeDasharray={circleCircumference}
                      strokeDashoffset={strokeDashoffset}
                      strokeLinecap="round"
                      transform="rotate(-90 30 30)"
                    />
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dy=".3em"
                      fontSize="15px"
                      fill="#007bff"
                    >
                      {percentage}%
                    </text>
                  </svg>
                  <div className="ml-3">
                    <div className="text-md">People Shown</div>
                    <div className="text-md">
                      <span className="text-blue-600 font-semibold mr-1">{formatNumber(searchSelector.search?.data.leads?.length)}</span>
                      of
                      <span className="text-gray-800 font-semibold ml-1">{formatNumber(searchSelector.search?.data.total)}</span>
                    </div>
                  </div>
                </div>
                <button
                  className="relative ml-auto mr-20 text-white bg-blue-600 rounded-full p-2 flex items-center focus:outline-none z-10"
                  onClick={handleSave}
                >
                  <p className="font-semibold px-10 text-lg">Save People</p>
                  <ArrowForwardIos />
                </button>
              </div>
              <NavIconAndPage
                leads={searchSelector.search?.data}
                fromPath={"search"}
              />
            </>
          )}
        </div>
      ) : (
        <div className="flex justify-center h-screen items-center">
          <div className="flex flex-col items-center w-2/3">
            <img
              src={`${process.env.PUBLIC_URL}/pulse-logo.png`}
              alt="pulse icon"
              className="flex-shrink-0 w-1/3 rounded-full mb-8"
            />

            {isCoinsAvailable ? (
              // Render Search Bar if Coins are Sufficient
              <div className="flex items-center w-full">
                <form
                  className="flex flex-col items-center gap-4 w-full"
                  onSubmit={
                    searchType === 'lead' && !meLoading && objective.length > 5
                      ? handleNewSearchSubmit
                      : handlePersonSearchSubmit
                  }
                >
                  <div className="flex items-center rounded-full bg-gray-200 dark:bg-gray-700 w-full">
                    <img
                      src={`${process.env.PUBLIC_URL}/pulse-logo-icon.png`}
                      alt="Logo"
                      className="rounded-full w-6 h-6 ml-4"
                    />
                    <input
                      type="search"
                      id="default-search"
                      className="flex-grow p-6 text-md font-md text-gray-900 dark:text-white focus:outline-none rounded-l-full bg-transparent"
                      placeholder="Search for your ICP by single title, industry, and location"
                      onChange={(e) => setObjective(e.target.value)}
                    />
                    <button
                      type="button"
                      className={`text-white font-medium rounded-md text-sm px-2 py-1 mr-4 ${objective.trim() ? 'bg-gray-900' : 'bg-gray-300'
                        }`}
                      onClick={
                        !meLoading && objective.length > 5 && isCoinsAvailable
                          ? handleNewSearchSubmit
                          : null
                      }
                      disabled={!isCoinsAvailable || !objective.trim()}
                    >
                      <div className="flex items-center">
                        <div data-tip="Searches cost 100 pulse coins" className="flex items-center">
                          <TollOutlined className="mr-1 text-yellow-500" />
                          <p className="text-yellow-500">100</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              // Show Tooltip and Error Message if Coins are Insufficient or Unavailable
              <div className="flex items-center p-4 mt-5 mr-4">
                <Tooltip place="top" effect="solid" className="z-50" />
                <div className="h-6 w-6 text-gray-500 mr-4">
                  <span className="material-icons text-blue-500">info</span>
                </div>
                <div>
                  <p className="text-sm text-gray-800">{tooltipText}</p>
                </div>
              </div>
            )}

          </div>
        </div>
      )}
    </>
  );
}


export default SearchCommunities;
